"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const startButton_1 = require("../objects/startButton");
const axios_1 = require("axios");
const WebFont = require("webfontloader");
/**
 * @author       Daniel Knoben, Scott Dunham, Leah Ruisenor, Digitsensitive
 * @copyright    2018 Digitsensitive
 * @license      Digitsensitive
 */
class StartScene extends Phaser.Scene {
    constructor() {
        super({
            key: "StartScene"
        });
    }
    preload() {
        let self = this;
        WebFont.load({
            custom: {
                families: ['Sans', 'Script'],
                urls: ['build/game.css']
            },
            active: function () {
                // Start the progress bar
                self.fontsLoaded = true;
            }
        });
        //Images
        this.load.image('mountains', './assets/game/mountains.png');
        this.load.image('deer', './assets/game/deer.png');
        this.load.image('intro', './assets/game/intro2.png');
        //Sprites
        this.load.spritesheet('startButton', './assets/game/play.png', { frameWidth: 96, frameHeight: 96 });
    }
    create() {
        let background = this.add.tileSprite(550, 390, 1200, 450, 'mountains');
        let deer = this.add.image(950, 310, 'deer');
        let introBox = this.add.image(300, 300, 'intro');
        this.btnStart = new startButton_1.startButton(this);
    }
    update() {
        // Add text once fonts are loaded
        if (this.fontsLoaded) {
            this.fontsLoaded = false;
            this.add.text(170, 160, "Those Presents Aren't going", { fontFamily: 'Sans', fontSize: '11pt', fill: '#0c5266' });
            this.add.text(195, 185, "to deliver themselves", { fontFamily: 'Sans', fontSize: '11pt', fill: '#0c5266' });
            // NO GIFTS FOR YOU
            //this.add.text(200 , 212, 'Top scores get gifts!', { fontFamily: 'Sans', fontSize: '11pt', fill: 'red' });
            this.createMiniLeaderboard();
        }
    }
    //Add High Scores and Initials Text to Mini Leaderboard
    createMiniLeaderboard() {
        let self = this;
        let xCordInitials = 575;
        let yCordInitials = 216;
        let xCordScore = 675;
        let yCordScore = 215;
        let timestamp = new Date().getTime();
        axios_1.default.get('/highScores?t=' + timestamp).then(function (data) {
            self.top3Text = self.add.text(570, 155, 'Top 3 Scores', { fontFamily: 'Script', fontSize: '24pt', fill: 'white' });
            // data from database top three
            var highScoreArr = data['data']['scores'];
            for (var i = 0; i < 3; i++) {
                // initials
                self.initialsText = self.add.text(xCordInitials, yCordInitials, String(highScoreArr[i]['initials']), { fontFamily: 'Sans', fontSize: '16pt', fill: '#b3dde3' });
                // score
                self.highScoreText = self.add.text(xCordScore, yCordScore, String(highScoreArr[i]['score']), { fontFamily: 'Sans', fontSize: '16pt', fill: 'white' });
                yCordInitials += 40;
                yCordScore += 40;
            }
        }).catch(function (err) {
            console.error(err);
        });
    } //end createLeaderboard
}
exports.StartScene = StartScene;
